import Range from './Range.js';
import AnalyticalMethod from './AnalyticalMethod.js';

export default class DetectorRunProperties {
	static #PROTON_MASS = 1.007825;

	constructor({
					id, analyticalMethod, detectorType, ionMode, scanWindow, unitsX, scanCompression, alignMinutes,
					sequence, description, domainBlobId
				}) {
		if (typeof alignMinutes !== 'number')
			throw new Error('AlignMinutes must be a number');
		this._id = id;
		this._type = detectorType;
		this._ionMode = ionMode;
		this._description = description;
		this._units = unitsX;
		this._alignMinutes = alignMinutes;
		this._scanWindow = scanWindow;
		this._scanCompression = scanCompression;
		this._sequence = sequence;
		this._domainBlobId = domainBlobId;
		this._analyticalMethod = analyticalMethod;
	}

	/** @returns {string} */
	getId() {
		return this._id
	}

	/** @returns {boolean} */
	isMs() {
		return this._analyticalMethod === AnalyticalMethod.MS
	}

	/** @returns {string} */
	getDetectorType() {
		return this._type
	}

	/** @returns {string} */
	getIonMode() {
		return this._ionMode
	}

	/** @returns {boolean} */
	isPositiveIonization(){
		return this._ionMode.endsWith("P")
	}

	/** @returns {boolean} */
	isElectronImpactMode(){
		return this._ionMode.startsWith("EI")
	}

	/** @returns {string} */
	getDescription() {
		return this._description
	}

	/** @returns {string} */
	getUnits() {
		return this._units
	}

	/** @return {number} */
	getAlignMinutes() {
		return this._alignMinutes;
	}

	setAlignMinutes(minutes) {
		this._alignMinutes = minutes
	}

	/** @return {Range} */
	getScanWindow() {
		return this._scanWindow
	}

	/**
	 * @returns {boolean} true for detectors like MS or DAD, but other detectors like CAD, MWD don't have spectra
	 */
	hasSpectra() {
		return !!this._scanCompression;
	}

	/** @return {string} */
	getScanCompression() {
		return this._scanCompression;
	}

	/** @return {string} */
	getSequence() {
		return this._sequence || '';
	}

	/** @return {string} */
	getDomainBlobId() {
		return this._domainBlobId;
	}

	/** @return {string} */
	getUniqueName() {
		return this.getDescription() + this.getSequence();
	}

	/** @returns {AnalyticalMethod} */
	getAnalyticalMethod() {
		return this._analyticalMethod;
	}

	/**
	 * Hardcoded for now, need to move it to the other class
	 * @returns {number}
	 */
	getChangesToIonMass() {
		if (!this.isMs())
			throw new Error('Cannot get changes to ion mass for non-MS detector run');
		const ionMode = this.getIonMode();
		switch (ionMode) {
			case 'ESP':
			case 'CIP':
			case 'AIP':
				return DetectorRunProperties.#PROTON_MASS;
			case 'ESM':
			case 'CIM':
			case 'AIM':
				return -DetectorRunProperties.#PROTON_MASS;
			default:
				return 0;
		}
	}

	static get PROTON_MASS(){
		return DetectorRunProperties.#PROTON_MASS;
	}

	/** @return {DetectorRunProperties} */
	static parseServerJson(json) {
		return new DetectorRunProperties({
			id: json.id,
			analyticalMethod: AnalyticalMethod.fromString(json.analyticalMethod),
			detectorType: json.detectorType,
			ionMode: json.ionMode,
			scanWindow: json.scanWindow ? Range.parseServerJson(json.scanWindow) : null,
			unitsX: json.units,
			scanCompression: json.spectrumCompression,
			alignMinutes: json.alignMin,
			sequence: json.seqNum,
			description: json.description,
			domainBlobId: json.blobs.domain
		});
	}
}
