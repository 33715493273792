export default class InjectionProperty {

    constructor(name, value) {
        this.name = name;
        this.value = value
    }

    /** @returns {InjectionProperty} */
    static empty () {
        return new InjectionProperty("", "")
    }

    /**
     * @param {{[key: string]: string}[]} json
     * @returns {InjectionProperty[]}
     */
    static parseServerJsonMany(json){
        const result = [];
        for (const key in json) {
            result.push(new InjectionProperty(key, json[key]))
        }
        return result;
    }
}
