import InjectionMetaJson from './InjectionMetaJson.js';
import MethodInfo from './MethodInfo.js';
import SubstanceJson from './SubstanceJson.js';
import DetectorRunProperties from './DetectorRunProperties.js';
import ChromatogramJson from './ChromatogramJson.js';
import IntegrationMethod from './chromatogram/integration/IntegrationMethod.js';
import PeakJson from './PeakJson.js';
import OnPlatePosition from "../components/plate/OnPlatePosition.js";
import Range from "./Range.js";
import UserJson from "./user/UserJson.js";
import AccessLevel from "./AccessLevel.js";
import InjectionProperty from "./injectionProperty/InjectionProperty.js";

export default class InjectionJson extends InjectionMetaJson{
  /**
   * @param {string} id
   * @param {string} name
   * @param {number} acquired
   * @param {string} plateId
   * @param {OnPlatePosition} pos
   * @param {string} instrumentName
   * @param {string} batchId
   * @param {MethodInfo} methodInfo
   * @param {DetectorRunProperties[]} runProperties
   * @param {SubstanceJson[]} substances
   * @param {ChromatogramJson[]} chromatograms
   * @param {PeakJson[]} peaks
   * @param {IntegrationMethod} integrationMethod
   * @param {Range} rtRange
   * @param {UserJson} creator
   * @param {AccessLevel} userAccessLevel
   * @param {string} orgId
   * @param {InjectionProperty[]} userDefinedProps
   */
  constructor({id, name, acquired, plateId, pos, instrumentName,
                batchId, methodInfo, runProperties, substances,
                chromatograms, peaks, integrationMethod, rtRange,
                creator, userAccessLevel, orgId, userDefinedProps
              }) {
    super(id, name, acquired, plateId, pos, instrumentName, methodInfo.inlet + ' ' + methodInfo.ms, creator, userAccessLevel, orgId);
    this._batchId = batchId;
    this._methodInfo = methodInfo;
    this._runProperties = runProperties;
    this._substances = substances;
    this._chromatograms = chromatograms;
    this._peaks = peaks;
    this._integrationMethod = integrationMethod;
    this._rtRange = rtRange;
    this._batchMeta = null;
    this._userDefinedProps = userDefinedProps;
  }

  getBatchId() {return this._batchId}
  /** @return {MethodInfo} */
  getMethodInfo() {return this._methodInfo}
  /** @return {DetectorRunProperties[]} */
  getDetectorRunProperties() {return this._runProperties;}
  /** @return {SubstanceJson[]} */
  getSubstances() {return this._substances}
  /** @param {SubstanceJson} substance */
  addSubstance(substance) {this._substances.push(substance)}
  /** @return {SubstanceJson} */
  getSubstanceById(id) {return this._substances.find(substance=>substance.getId() === id)}
  /** @param {string} substanceId */
  deleteSubstance(substanceId){this._substances = this._substances.filter(s=>s.getId() !== substanceId)}
  /** @return {ChromatogramJson[]} */
  getChromatograms() {return this._chromatograms}
  /** @return {PeakJson[]} */
  getPeaks() {return this._peaks}
  /** @returns {IntegrationMethod} */
  getIntegrationMethod(){
    return this._integrationMethod;
  }
  /** @returns {InjectionProperty[]} */
  getUserDefinedProps(){
    return this._userDefinedProps;
  }

  /**
   * @returns {BatchMeta|null}
   */
  getBatchMeta() {
    return this._batchMeta
  }

  /**
   * @param {BatchMeta|null} batchMeta
   */
  setBatchMeta(batchMeta) {
    this._batchMeta = batchMeta
  }

  /** @param {InjectionProperty[]}props */
  setUserDefinedProps(props){
    this._userDefinedProps = props
  }

  /** @returns {string} */
  get orgId() {
    return super.orgId();
  }

  /**
     * @returns {Range}
     */
    getRtRange() {
        return this._rtRange;
    }

    /**
     * @returns {boolean}
     */
    isWritable() {
      return this._userAccessLevel.meetsOrExceeds(AccessLevel.WRITE);
    }

  static parseServerJson(json){
    const id = json.id;
    const injectionName = json.name;
    const acquired = json.acquired;
    const plateId = json.plateId;
    const pos = new OnPlatePosition(json.row, json.col);
    const instrumentName = json.instrumentName;
    const batchId = json.batchId;
    const methodInfo = MethodInfo.parseServerJson(json.methodInfo);
    const runProperties = json.detectorRuns.map(prop=>DetectorRunProperties.parseServerJson(prop));
    const substances = json.substances.map(prop=>SubstanceJson.parseServerJson(prop));
    const chromatograms = json.chromatograms.map(prop=>ChromatogramJson.parseServerJson(prop));
    const peaks = json.peaks.map(prop=>PeakJson.parseServerJson(prop));
    const integrationMethod = IntegrationMethod.parseServerJson(json.integrationMethod);
    const creator = UserJson.parseServerJson(json.creator);
    const userAccessLevel = json.userAccessLevel == null ? AccessLevel.WRITE : new AccessLevel(json.userAccessLevel);
    const userDefinedProps = InjectionProperty.parseServerJsonMany(json.userDefinedProps);
    return new InjectionJson({
      id, name: injectionName, acquired, plateId, pos, instrumentName, batchId, methodInfo, runProperties, substances,
      chromatograms, peaks, integrationMethod, rtRange: Range.parseServerJson(json.rtRange),
      creator, userAccessLevel, orgId: json.org.id, userDefinedProps
    });
  }
}