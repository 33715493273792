import IntegrationAlgorithm from './IntegrationAlgorithm.js';
import IntegrationScope from './IntegrationScope.js';
import Range from "../../Range.js";

export default class IntegrationMethod {

    /**
     * @param {Map<string, IntegrationAlgorithm>} scopesToAlgorithms
     */
    constructor(scopesToAlgorithms) {
        if(!scopesToAlgorithms)
            throw new Error();
        /** @type {Map<string, IntegrationAlgorithm>} */
        this._algorithms = scopesToAlgorithms;
    }

    /**
     * @param {string} chromatogramName
     * @param {IntegrationAlgorithm} algorithm
     */
    putAlgorithm(chromatogramName, algorithm) {
        if (!(algorithm instanceof IntegrationAlgorithm))
            throw new Error(`You're trying to put ${algorithm.constructor.name} instead of IntegrationAlgorithm object.`);
        this._algorithms.set(chromatogramName, algorithm);
    }

    /**
     * @param {IntegrationScope} chromatogramName
     * @returns {IntegrationAlgorithm}
     */
    getClosestAlgorithm(chromatogramName) {
        let key = chromatogramName.getName();
        do {
            const algorithm = this._algorithms.get(key);
            if (algorithm != null)
                return algorithm;
            let lowerPriorityKey = key.substring(0, key.lastIndexOf(' '));
            if (key === lowerPriorityKey) {
                let custom = this._algorithms.get("CUSTOM");
                if (custom==null) { throw new Error(`Algorithm with name ${chromatogramName.getName()} was not found`); }
                return custom;
            }
            key = lowerPriorityKey;
        } while (true);
    }
    /**
     * @param {IntegrationScope} scope
     * @param {Range} integrableRange
     */
    updateIntegrableRange(scope, integrableRange) {
        const alg = this.getClosestAlgorithm(scope).withIntegrableRange(
            new Range(integrableRange.lower, integrableRange.upper)
        );
        this._algorithms.set(scope.getName(), alg);
    }
    /**
     * @param {IntegrationScope} scope
     * @param {IntegrationPeakFilters} peakFilters
     */
    updatePeakFilters(scope, peakFilters) {
        const alg = this.getClosestAlgorithm(scope).withIntegrationPeakFilters(peakFilters.copy());
        this._algorithms.set(scope.getName(), alg);
    }
    /**
     * @param {IntegrationScope} scope
     * @param {PeakDetectorProperties} peakDetectorProps
     */
    updatePeakDetectorProps(scope, peakDetectorProps) {
        const alg = this.getClosestAlgorithm(scope).withPeakDetectorProperties(peakDetectorProps.copy());
        this._algorithms.set(scope.getName(), alg);
    }

    /** @param {string} chromatogramName */
    removeAlgorithm(chromatogramName) {
        this._algorithms.delete(chromatogramName)
    }

    /** @return {IntegrationScope[]} */
    getScopes() {
        const scopes = [];
        for (const key of this._algorithms.keys())
            scopes.push(new IntegrationScope(key))
        return scopes;
    }

    /**
     * @param {string[]} scopes
     * @return {IntegrationMethod}
     */
    withScopesFiltered(scopes) {
        const map = new Map();
        for (const scope of scopes)
            map.set(scope, this._algorithms.get(scope));
        return new IntegrationMethod(map);
    }

    toServerJson() {
        const map = new Map();
        for (const [key, value] of this._algorithms)
            map.set(key, value.toServerJson());
        return Object.fromEntries(map);
    }
    /**
     * @param json
     * @return {IntegrationMethod}
     */
    static parseServerJson(json) {
        const algorithms = new Map();
        for (const key in json)
            algorithms.set(key, IntegrationAlgorithm.parseServerJson(json[key]));
        return new IntegrationMethod(algorithms);
    }
}