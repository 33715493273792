import InjectionMetaJson from "../entities/InjectionMetaJson.js";
import InjectionJson from "../entities/InjectionJson.js";
import PeakList from "../util/PeakList.js";

export default class InjectionRepository {
	#httpClient;
	#endpointUrl;

	constructor(httpClient, endpointUrl = '/api/injection') {
		this.#httpClient = httpClient;
		this.#endpointUrl = endpointUrl;
	}

	/**
	 * @param {string|null} orgId
	 * @param {string} nameFilter
	 * @param {number} page
	 * @returns {Promise<{injections:InjectionMetaJson[], total:number, isLastPage:boolean}>}
	 */
	async getInjectionsByName(orgId, nameFilter, page = 0) {
		const orgTemplate = orgId.length ? `orgId=${encodeURIComponent(orgId)}&` : "";
		const response = await this.#httpClient
			.fetch(`/api/query/ORPHAN_INJECTIONS?${orgTemplate}name=${encodeURIComponent(nameFilter)}&offset=${encodeURIComponent(page*50)}&checkAcl=true`, {
				method: 'GET',
				headers: {
					"Accept": "application/json",
				},
				responseType: 'json'
			});
		const content = response.content || [];
		const list = content.map(json => InjectionMetaJson.parseServerJson(json));
		return {injections: list, total: response.total, isLastPage: response.last};
	}

	/**
	 * @param {string} injectionId
	 * @return {InjectionJson}
	 */
	async getInjectionById(injectionId) {
		const response = await this.#httpClient
			.fetch(`${this.#endpointUrl}/${injectionId}`, {
				method: 'GET',
				headers: {
					"Accept": "application/json"
				},
				responseType: 'json'
			});
		return InjectionJson.parseServerJson(response);
	}

	/**
	 * @param {string[]} injectionIds
	 * @param {IntegrationMethod} method
	 */
	async reintegrateInjections(injectionIds, method) {
		const methodJson = method.toServerJson();
		await this.#httpClient
			.fetch(`${this.#endpointUrl}/reintegrate`, {
				method: 'PUT',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({injections: injectionIds, method: methodJson})
			});
	}

	/**
	 * @param {string} injectionId
	 * @param {string} chromatogramId
	 * @param {FloatPoint[]} floatPoints
	 */
	async saveBaselineAnchors(injectionId, chromatogramId, floatPoints) {
		const response = await this.#httpClient
			.fetch(`${this.#endpointUrl}/${injectionId}/baselineAnchors/${chromatogramId}`, {
				method: 'PUT',
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify(floatPoints.map(fp => fp.toServerJson())),
				responseType: 'json'
			});
		return PeakList.fromServerJson(response);
	}

	uploadInjection(orgId, file, onUploadProgress) {
		return this.#httpClient.cancelableFetch(`${this.#endpointUrl}?orgId=${encodeURIComponent(orgId)}`, {
			method: 'POST',
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/octet-stream"
			},
			body: file,
			onUploadProgress: onUploadProgress,
			responseType: 'json'
		});
	}

	async split(injectionId, splitReq){
		return await this.#httpClient
			.fetch(`${this.#endpointUrl}/${injectionId}/split`, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json"
				},
				responseType: 'json',
				body: JSON.stringify(splitReq)
			});
	}

	/** @param {string[]}injectionIds */
	async deleteInjections(injectionIds){
		return this.#httpClient.fetch(`${this.#endpointUrl}?id=` + `${injectionIds.join(',')}`, {
			method: 'DELETE',
			headers: {
				"Content-Type": "application/json"
			},
			responseType: 'json'
		});
	}

	/**
	 * @param {string} injectionId
	 * @param {{[key: string]: string}[]} props
	 */
	async saveUserDefinedProps(injectionId, props){
		await this.#httpClient.fetch(`${this.#endpointUrl}/${encodeURIComponent(injectionId)}/props/userdefined`, {
			method: 'PUT',
			headers: {
				"Accept": "application/json"
			},
			responseType: 'json',
			body: JSON.stringify(props)
		});
	}
}